import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'

import { Menu, Dropdown, Image } from 'semantic-ui-react'

import { UserContext } from '../lib/context'
import { DASHBOARD_URL, FLAGGED_URL, USERS_URL, VERIFY_URL, LOG_URL } from '../lib/consts'

import logo from '../assets/logo-player.png'

const AppMenu = () => {
  const { name, pictureUrl, logout } = useContext( UserContext )

  return (
    <Menu fixed="top" inverted color="blue" stackable>
      <Menu.Item>
        <Image src={logo} size="mini" />
      </Menu.Item>

      <Menu.Item as={NavLink} to={DASHBOARD_URL} name="Dashboard" />
      <Menu.Item as={NavLink} to={FLAGGED_URL} name="Flagged" />
      <Menu.Item as={NavLink} to={USERS_URL} name="Users" />
      <Menu.Item as={NavLink} to={VERIFY_URL} name="Verify" />
      <Menu.Item as={NavLink} to={LOG_URL} name="Action Log" />

      <Menu.Item position="right">
        <Image src={pictureUrl} avatar />
        <Dropdown item text={name}>
          <Dropdown.Menu>
            <Dropdown.Item text="Log out" onClick={logout} />
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
    </Menu>
  )
}
export default AppMenu
