import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { func } from 'prop-types'
import { location } from 'react-router-prop-types'

import { LOGIN_URL } from '../lib/consts'
import { UserContext } from '../lib/context'

const PrivateRoute = ( { component: Component, location, ...props } ) => {
  const { authenticated } = useContext( UserContext )

  return (
    <Route
      {...props}
      render={props => ( authenticated
        ? <Component {...props} />
        : <Redirect to={{ pathname: LOGIN_URL, state: { from: location } }} /> )
    }
    />
  )
}

PrivateRoute.propTypes = {
  component: func.isRequired,
  location: location.isRequired,
}

export default PrivateRoute
