import React, { useState, useEffect } from 'react'
import { Container, Segment } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import useReactRouter from 'use-react-router'

import { USERS_URL } from '../lib/consts'
import { getUser } from '../lib/controller'

import SearchBar from '../components/SearchBar'
import Profile from '../components/Profile'

const Users = () => {
  const [ user, setUser ] = useState( null )
  const { match: { params: { id } }, history } = useReactRouter()

  const setUserUrl = id => history.push( `${USERS_URL}/${id}` )
  const loadUser = () => getUser( id ).then( setUser ).catch( ( { err } ) => toast.error( err ) )

  useEffect( () => { if ( id ) loadUser() }, [ id ] )

  return (
    <Container className="users">
      <SearchBar fluid placeholder="Name or Email" onResultSelect={setUserUrl} />
      {user && (
      <Segment>
        <Profile {...user} refresh={loadUser} />
      </Segment>
      ) }
    </Container>
  )
}

export default Users
