import React, { useEffect, useState } from 'react'
import { string, shape } from 'prop-types'
import { toast } from 'react-toastify'

import { Table, Container, Header, Visibility } from 'semantic-ui-react'

import { getEvents } from '../lib/controller'

import './Log.css'

const Row = props => {
  const { user, comment, post, timestamp, id, event, moderator } = props

  let type
  if ( comment ) type = 'comment'
  else if ( post ) type = 'post'
  else type = 'user'

  const getContent = () => ( {
    comment: () => comment.comment,
    post: () => (
      <span>
        <a href={post.clipUrl} target="_blank" rel="noopener noreferrer">View Clip</a>
        <p>{post.caption}</p>
      </span>
    ),
    user: () => <a href={`/users/${user.id}`} target="_blank" rel="noopener noreferrer">View Profile</a>,
  } )[ type ]()

  return (
    <Table.Row key={id}>
      <Table.Cell>{new Date( timestamp ).toUTCString()}</Table.Cell>
      <Table.Cell>{`${event} ${type}`}</Table.Cell>
      <Table.Cell>{moderator.name}</Table.Cell>
      <Table.Cell>{user.name}</Table.Cell>
      <Table.Cell>
        {getContent()}
      </Table.Cell>
    </Table.Row>
  )
}

Row.propTypes = {
  id: string.isRequired,
  timestamp: string.isRequired,
  user: shape( { name: string, id: string } ).isRequired,
  moderator: shape( { name: string, id: string } ).isRequired,
  post: shape( { id: string, caption: string } ),
  comment: shape( { comment: string, id: string } ),
  event: string.isRequired,
}

Row.defaultProps = {
  post: null,
  comment: null,
}

const Log = () => {
  const [ events, setEvents ] = useState( [] )
  const [ timestamp, setTimestamp ] = useState( Date.now() )

  const loadEvents = () => getEvents( timestamp )
    .then( ( { events: newEvents } ) => setEvents( [ ...events, ...newEvents ] ) )
    .catch( ( { err } ) => toast.error( err ) )

  useEffect( () => { loadEvents() }, [ timestamp ] )

  const loadMore = () => {
    const [ { timestamp } ] = events.slice( -1 )
    setTimestamp( new Date( timestamp ).getTime() )
  }

  return (
    <Container className="log">
      <Header textAlign="center">Action Log</Header>

      <Visibility continuous onBottomVisible={loadMore}>
        <Table celled striped selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Timestamp</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
              <Table.HeaderCell>Moderator</Table.HeaderCell>
              <Table.HeaderCell>Target User</Table.HeaderCell>
              <Table.HeaderCell>Content</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {events.map( props => <Row {...props} /> )}
          </Table.Body>
        </Table>
        {!events.length && <Header size="huge" textAlign="center">All clear :)</Header>}
      </Visibility>

    </Container>
  )
}
export default Log
