/**
 * Environment Variables
 */
const { env: { NODE_ENV, API_SERVER } } = process

export const isDev = NODE_ENV === 'development'
export const isStaging = API_SERVER.indexOf( 'dev' ) > -1

/**
 * API Endpoints
 */
export const ADMIN_API = `${API_SERVER}/admin`
export const PROFILE_API = `${API_SERVER}/profiles`
export const SEARCH_API = `${API_SERVER}/search`

/**
 * Page URLs
 */
export const LOGIN_URL = '/login'
export const DASHBOARD_URL = '/dashboard'
export const FLAGGED_URL = '/flagged'
export const USERS_URL = '/users'
export const VERIFY_URL = '/verify'
export const LOG_URL = '/audit'
