// Import React
import 'react-hot-loader'
import React from 'react'
import ReactDOM from 'react-dom'

import * as Sentry from '@sentry/browser'

// Import Semantic UI Styles
import 'semantic-ui-css/semantic.min.css'

import 'react-toastify/dist/ReactToastify.css'

import { isDev, isStaging } from './lib/consts'

// Import App Component
import App from './App'

export const getEnvironment = () => {
  if ( isDev ) return 'local'
  if ( isStaging ) return 'staging'
  return 'production'
}

Sentry.init( {
  enabled: !isDev,
  dsn: 'https://efe27899dfe2468ab66cdc7c2eeb66dd@sentry.io/1499926',
  environment: getEnvironment(),
} )

// Mount React App
ReactDOM.render( <App />, document.getElementById( 'app' ) )
