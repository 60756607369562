import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { Container, Header, Icon, Grid } from 'semantic-ui-react'

import GroupStatistic from '../components/GroupStatistic'

import { getStats } from '../lib/controller'

const Dashboard = () => {
  const [ { newUsers, activeUsers, totalUsers }, setStats ] = useState( {
    activeUsers: { month: {}, week: {} },
    newUsers: { month: {}, week: {} },
    totalUsers: 0,
  } )

  useEffect( () => {
    getStats().then( setStats ).catch( ( { err } ) => toast.error( err ) )
  }, [] )

  return (
    <Container className="dashboard">
      <Header size="huge" textAlign="center" icon>
        <Icon name="industry" />
        <Header.Content>Statistics</Header.Content>
      </Header>

      <Grid className="stats" padded divided columns={5} doubling textAlign="center">
        <Grid.Row className="total">

          <GroupStatistic label="New Users/7d" players={newUsers.week.players} scouts={newUsers.week.scouts} />
          <GroupStatistic label="New Users/30d" players={newUsers.month.players} scouts={newUsers.month.scouts} />
          <GroupStatistic label="Active Users/7d" players={activeUsers.week.players} scouts={activeUsers.week.scouts} />
          <GroupStatistic label="Active Users/30d" players={activeUsers.month.players} scouts={activeUsers.month.scouts} />
          <GroupStatistic label="Total Users" value={totalUsers} />

        </Grid.Row>
      </Grid>
    </Container>
  )
}

export default Dashboard
