import React, { useState } from 'react'
import { func } from 'prop-types'
import { Button } from 'semantic-ui-react'

const ConfirmButton = ( { onClick, ...props } ) => {
  const [ clicked, setClicked ] = useState( false )
  const [ loading, setLoading ] = useState( false )

  const passThroughClick = async () => {
    setLoading( true )

    await onClick()
    setClicked( false )
    setLoading( false )
  }

  const confirmProps = {
    ...( clicked && { content: 'Are you sure?', onClick: passThroughClick } ),
    ...( !clicked && { onClick: () => setClicked( true ) } ),
  }

  return <Button {...props} {...confirmProps} loading={loading} />
}

ConfirmButton.propTypes = {
  onClick: func,
}

ConfirmButton.defaultProps = {
  onClick: () => Promise.resolve(),
}

export default ConfirmButton
