import React, { useState, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { location } from 'react-router-prop-types'
import { toast } from 'react-toastify'

import { Header } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'

import { UserContext } from '../lib/context'

import LogoHeader from '../assets/logo-header.svg'

import './Login.css'

const Login = ( { location } ) => {
  const { login } = useContext( UserContext )

  const [ { email, password }, setForm ] = useState( { email: '', password: '' } )

  const [ loading, setLoading ] = useState( false )
  const [ redirectToReferrer, setRedirectToReferrer ] = useState( false )

  const { from } = location.state || { from: { pathname: '/' } }

  const loginWithRedirect = () => {
    setLoading( true )
    return login( email, password )
      .then( () => setRedirectToReferrer( true ) )
      .catch( () => toast.error( 'Unable to login. Check your username and password.' ) )
      .finally( () => setLoading( false ) )
  }

  const setField = ( _, { name, value } ) => setForm( { email, password, [ name ]: value } )

  if ( redirectToReferrer ) return <Redirect to={from} />

  return (
    <main className="login">
      <div className="logo">
        <img src={LogoHeader} alt="Logo" />
        <Header inverted textAlign="center" size="huge">Moderate</Header>
      </div>
      <Form onValidSubmit={loginWithRedirect} className="form" size="large">
        <Form.Input
          fluid
          name="email"
          type="email"
          placeholder="Email Address"
          required
          instantValidation
          validations="isEmail"
          onChange={setField}
          value={email}
        />
        <Form.Input
          fluid
          name="password"
          type="password"
          placeholder="Password"
          required
          instantValidation
          validations="isExisty"
          onChange={setField}
          value={password}
        />
        <Form.Button loading={loading} fluid color="green">Login</Form.Button>
      </Form>
    </main>
  )
}

Login.propTypes = {
  location: location.isRequired,
}

export default Login
