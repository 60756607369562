import React, { useEffect, useState } from 'react'
import { shape, string, number, arrayOf } from 'prop-types'
import { toast } from 'react-toastify'

import { Table, Container, Header, Label, Visibility } from 'semantic-ui-react'

import { getFlagged, dismissItem, removeItem } from '../lib/controller'

import ConfirmButton from '../components/ConfirmButton'

import './Flagged.css'

const Reasons = ( { reasons } ) => reasons.map( reason => <Label className="reason" key={reason} color="teal">{reason}</Label> )

const Row = props => {
  const { user, comment, post, timestamp, id, count, reasons } = props

  const [ disabled, setDisabled ] = useState( false )
  const onActionClick = fn => () => {
    setDisabled( true )
    fn()
  }

  let type
  if ( comment ) type = 'comment'
  else if ( post ) type = 'post'
  else type = 'user'

  const getContent = () => ( {
    comment: () => comment.comment,
    post: () => (
      <span>
        <a href={post.clipUrl} target="_blank" rel="noopener noreferrer">View Clip</a>
        <p>{post.caption}</p>
      </span>
    ),
    user: () => <a href={`/users/${user.id}`} target="_blank" rel="noopener noreferrer">View Profile</a>,
  } )[ type ]()

  return (
    <Table.Row key={id} disabled={disabled} negative={user.offenceCount}>
      <Table.Cell>{new Date( timestamp ).toUTCString()}</Table.Cell>
      <Table.Cell>{type}</Table.Cell>
      <Table.Cell>
        <p>{user.name}</p>
        <p>{user.offenceCount && `${user.offenceCount}x Previous Offender`}</p>
      </Table.Cell>
      <Table.Cell>
        {getContent()}
      </Table.Cell>
      <Table.Cell>{count}</Table.Cell>
      <Table.Cell><Reasons reasons={reasons} /></Table.Cell>
      <Table.Cell>
        <ConfirmButton disabled={disabled} color="red" onClick={onActionClick( () => removeItem( id ) )} content="Remove" />
        <ConfirmButton disabled={disabled} onClick={onActionClick( () => dismissItem( id ) )} content="Dismiss" />
      </Table.Cell>
    </Table.Row>
  )
}

Row.propTypes = {
  user: shape( { name: string, id: string } ).isRequired,
  post: shape( { id: string, caption: string } ),
  comment: shape( { comment: string, id: string } ),
  id: string.isRequired,
  timestamp: string.isRequired,
  count: number.isRequired,
  reasons: arrayOf( string ).isRequired,
}

Row.defaultProps = {
  post: null,
  comment: null,
}

const Flagged = () => {
  const [ flagged, setFlagged ] = useState( [] )
  const [ timestamp, setTimestamp ] = useState( 0 )

  const loadFlagged = () => getFlagged( timestamp )
    .then( ( { reported } ) => setFlagged( [ ...flagged, ...reported ] ) )
    .catch( ( { err } ) => toast.error( err ) )

  useEffect( () => { loadFlagged() }, [ timestamp ] )

  const loadMore = () => {
    const [ { timestamp } ] = flagged.slice( -1 )
    setTimestamp( new Date( timestamp ).getTime() )
  }

  return (
    <Container className="flagged">
      <Header textAlign="center">Flagged Content</Header>

      <Visibility continuous onBottomVisible={loadMore}>
        <Table celled striped selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Timestamp</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Offender</Table.HeaderCell>
              <Table.HeaderCell>Reported Content</Table.HeaderCell>
              <Table.HeaderCell>Count</Table.HeaderCell>
              <Table.HeaderCell>Reasons</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {flagged.map( props => <Row {...props} /> )}
          </Table.Body>
        </Table>
        {!flagged.length && <Header size="huge" textAlign="center">All clear :)</Header>}
      </Visibility>

    </Container>
  )
}
export default Flagged
