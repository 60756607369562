import React, { useState } from 'react'
import { func } from 'prop-types'
import { debounce } from 'lodash'

import { Search } from 'semantic-ui-react'
import { toast } from 'react-toastify'

import { getClub } from '../lib/utils'
import { search } from '../lib/controller'

import defaultAvatar from '../assets/default-avatar.png'

const SearchBar = ( { onResultSelect, ...props } ) => {
  const [ isLoading, setLoading ] = useState( false )
  const [ results, setResults ] = useState( null )

  const onSearch = debounce( async ( _, { value: param } ) => {
    setLoading( true )

    const field = param.indexOf( '@' ) === -1 ? 'name' : 'email'
    const filters = { [ field ]: { param } }

    const results = await search( filters )
      .then( ( { results } ) => results )
      .catch( ( { err } ) => toast.error( err ) )

    setLoading( false )
    setResults( results.map( ( { id, name, club, type, pictureUrl } ) => ( {
      key: id,
      id,
      title: name,
      description: getClub( { club, type } ),
      image: pictureUrl || defaultAvatar,
    } ) ) )
  }, 300 )

  return (
    <Search
      {...props}
      loading={isLoading}
      onSearchChange={onSearch}
      results={results}
      onResultSelect={( _, { result: { id } } ) => onResultSelect( id )}
    />
  )
}

SearchBar.propTypes = {
  onResultSelect: func,
}

SearchBar.defaultProps = {
  onResultSelect: () => {},
}

export default SearchBar
