import React, { useState, useEffect } from 'react'
import { string, bool, func, shape, arrayOf, number } from 'prop-types'
import { toast } from 'react-toastify'
import { Container, Header, Tab, Image, Grid, Label, List, Divider, Table } from 'semantic-ui-react'

import { SCOUT_TYPE, PLAYER_TYPE, SPECTATOR_TYPE } from '@skouted/common/lib/consts'

import Button from './ConfirmButton'

import { getAge, getClub } from '../lib/utils'
import { banUser, unbanUser, makeModerator, removeModerator, getOffences } from '../lib/controller'

import defaultAvatar from '../assets/default-avatar.png'

import './Profile.css'

const Info = ( {
  id,
  dob,
  email,
  phone,
  gender,
  lastLoginAt,
  createdAt,
} ) => (
  <Container>
    <Divider clearing hidden />
    <Grid doubling stackable divided>
      <Grid.Column width="5">
        <Header sub>Personal Information</Header>
        <List
          size="large"
          relaxed
          items={[
            { header: 'Age', content: getAge( dob ) },
            { header: 'Date of Birth', content: ( dob || '' ).split( 'T' )[ 0 ] },
            { header: 'Gender', content: gender },
          ]}
        />
      </Grid.Column>

      <Grid.Column width="5">
        <Header sub>Contact Information</Header>
        <List
          size="large"
          relaxed
          items={[
            { header: 'Email Address', content: email },
            { header: 'Phone Number', content: phone },
          ]}
        />
      </Grid.Column>

      <Grid.Column width="5">
        <Header sub>Account Information</Header>
        <List
          size="large"
          relaxed
          items={[
            { header: 'ID', content: id },
            { header: 'Account Created', content: new Date( createdAt ).toUTCString() },
            { header: 'Last Login', content: new Date( lastLoginAt ).toUTCString() },
          ]}
        />
      </Grid.Column>
    </Grid>
  </Container>
)

Info.propTypes = {
  id: string.isRequired,
  dob: string.isRequired,
  email: string.isRequired,
  phone: string,
  gender: string.isRequired,
  lastLoginAt: string,
  createdAt: string.isRequired,
}

Info.defaultProps = {
  phone: 'N/A',
  lastLoginAt: 'Never',
}

const Row = props => {
  const { comment, post, timestamp, id, count, reasons } = props

  let type
  if ( comment ) type = 'comment'
  else if ( post ) type = 'post'
  else type = 'user'

  const getContent = () => ( {
    comment: () => comment.comment,
    post: () => (
      <span>
        <a href={post.clipUrl} target="_blank" rel="noopener noreferrer">View Clip</a>
        <p>{post.caption}</p>
      </span>
    ),
    user: () => 'N/A',
  } )[ type ]()

  return (
    <Table.Row key={id}>
      <Table.Cell>{new Date( timestamp ).toUTCString()}</Table.Cell>
      <Table.Cell>{type}</Table.Cell>
      <Table.Cell>
        {getContent()}
      </Table.Cell>
      <Table.Cell>{count}</Table.Cell>
      <Table.Cell>
        {reasons.map( reason => <Label className="reason" key={reason} color="teal">{reason}</Label> )}
      </Table.Cell>
    </Table.Row>
  )
}

Row.propTypes = {
  post: shape( { id: string, caption: string } ),
  comment: shape( { comment: string, id: string } ),
  id: string.isRequired,
  timestamp: string.isRequired,
  count: number.isRequired,
  reasons: arrayOf( string ).isRequired,
}

Row.defaultProps = {
  post: null,
  comment: null,
}

const History = ( { id } ) => {
  const [ offences, setOffences ] = useState( [] )

  useEffect( () => {
    getOffences( id )
      .then( ( { offences } ) => setOffences( offences ) )
      .catch( ( { err } ) => toast.error( err ) )
  }, [] )

  return (
    <Container>
      <Divider clearing hidden />
      <Table celled striped selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Timestamp</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Reported Content</Table.HeaderCell>
            <Table.HeaderCell>Count</Table.HeaderCell>
            <Table.HeaderCell>Reasons</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {offences.map( props => <Row {...props} /> )}
        </Table.Body>
      </Table>
      {!offences.length && <Header size="huge" textAlign="center">All clear :)</Header>}
    </Container>
  )
}

History.propTypes = {
  id: string.isRequired,
}

const getLabel = type => ( {
  [ SCOUT_TYPE ]: <Label color="pink">Scout</Label>,
  [ PLAYER_TYPE ]: <Label color="violet">Player</Label>,
  [ SPECTATOR_TYPE ]: <Label color="brown">Fan</Label>,
} )[ type ]

const Profile = props => {
  const { id, name, club, pictureUrl, type, bannedAt, verified, isModerator, refresh } = props

  const isScout = type === SCOUT_TYPE

  const panes = [
    { menuItem: 'Info', render: () => <Info {...props} /> },
    { menuItem: 'Offence History', render: () => <History {...props} /> },
  ]

  const refreshAndCatch = fn => () => fn( id )
    .then( refresh )
    .catch( ( { err } ) => toast.error( err ) )

  const onBanClick = refreshAndCatch( banUser )
  const onUnbanClick = refreshAndCatch( unbanUser )

  const onMakeModeratorClick = refreshAndCatch( makeModerator )
  const onRemoveModeratorClick = refreshAndCatch( removeModerator )

  return (
    <Container className="profile">
      <Header className="header" as="h1">
        <Grid.Column className="profile-pic">
          <Image avatar src={pictureUrl || defaultAvatar} />
        </Grid.Column>

        <Grid.Column>
          {name}
          <Header sub>{getClub( { type, club } )}</Header>
        </Grid.Column>

        <Grid.Column className="labels">
          {getLabel( type )}
          {bannedAt && <Label color="red">Banned</Label>}
          {isScout && ( verified
            ? <Label color="blue">Verified</Label>
            : <Label>Unverified</Label> )
          }
          {isModerator && <Label color="green">Moderator</Label>}
        </Grid.Column>

      </Header>
      <Tab panes={panes} />

      <Divider hidden />

      <Container className="actions" fluid textAlign="center">
        { isScout && <Button color="green" icon="checkmark" content="Verify scout" />}
        <Button
          color="red"
          icon="ban"
          {...( bannedAt ? { content: 'Unban', onClick: onUnbanClick } : { content: 'Ban', onClick: onBanClick } )}
        />
        <Button
          color="orange"
          icon="user secret"
          {...( isModerator ? { content: 'Remove moderator', onClick: onRemoveModeratorClick } : { content: 'Make moderator', onClick: onMakeModeratorClick } )}
        />
      </Container>
    </Container>
  )
}

Profile.propTypes = {
  id: string.isRequired,
  club: string,
  verified: bool,
  pictureUrl: string,
  name: string.isRequired,
  isModerator: bool,
  type: string.isRequired,
  bannedAt: string,
  refresh: func,
}

Profile.defaultProps = {
  verified: null,
  club: null,
  pictureUrl: defaultAvatar,
  isModerator: false,
  bannedAt: null,
  refresh: () => {},
}

export default Profile
