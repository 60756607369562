import React, { Component } from 'react'
import { hot } from 'react-hot-loader/root'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import * as Sentry from '@sentry/browser'

import { UserContext } from './lib/context'
import { getUser, logout, login } from './lib/controller'
import { refreshToken, getUserId } from './lib/fetch'
import { LOGIN_URL, DASHBOARD_URL, FLAGGED_URL, USERS_URL, VERIFY_URL, LOG_URL } from './lib/consts'

import Menu from './components/Menu'
import PrivateRoute from './components/PrivateRoute'

import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import Flagged from './pages/Flagged'
import Users from './pages/Users'
import Verify from './pages/Verify'
import Log from './pages/Log'

import './App.css'

class App extends Component {
   state = {
     user: {
       id: getUserId(),
       refresh: () => this.refreshUser(),
       logout: () => this.logout(),
       login: ( ...params ) => this.login( ...params ),
       authenticated: !!getUserId(),
     },
   }

   componentDidCatch( error, errorInfo ) {
     // Capture non-dev errors with Sentry
     Sentry.withScope( scope => {
       scope.setExtras( errorInfo )
       Sentry.captureException( error )
     } )
   }

   componentDidMount() {
     this.refreshUser()
   }

   componentDidUpdate( _, { user: { id: prevId } } ) {
     const { user: { id } } = this.state
     // Fetch the user's data
     if ( prevId !== id ) this.refreshUser()
   }

  /**
   * Fetches the user's data, updating the state on completion.
   */
  refreshUser = async () => {
    const { user } = this.state
    const { id } = user

    if ( !getUserId() ) return

    await refreshToken( true ).catch( () => this.setState( ( { user } ) => ( {
      user: { ...user, authenticated: false },
    } ) ) )

    await getUser( id )
      .then( data => this.setState( { user: { ...user, ...data } } ) )
      .catch( () => toast.error( 'Expired credentials. Please login.' ) )
  }

  logout = () => {
    logout()
    this.setState( ( { user } ) => ( {
      user: { ...user, authenticated: false },
    } ) )
  }

  login = async ( email, password ) => {
    await login( email, password )

    this.setState( ( { user } ) => ( {
      user: {
        ...user,
        id: getUserId(),
        authenticated: true,
      },
    } ) )
  }

  render() {
    const { user } = this.state

    return (
      <UserContext.Provider value={user}>
        <div className="app">
          <Router>
            <PrivateRoute path="/" component={Menu} />

            <Switch>
              <Route path={LOGIN_URL} component={Login} />
              <PrivateRoute path={DASHBOARD_URL} component={Dashboard} />
              <PrivateRoute path={FLAGGED_URL} component={Flagged} />
              <PrivateRoute path={`${USERS_URL}/:id?`} component={Users} />
              <PrivateRoute path={VERIFY_URL} component={Verify} />
              <PrivateRoute path={LOG_URL} component={Log} />
              <Redirect to={DASHBOARD_URL} />
            </Switch>

          </Router>

          <ToastContainer position="bottom-right" />
        </div>
      </UserContext.Provider>
    )
  }
}

export default hot( App )

