/* Augments fetch with auto-refreshing capabilities */
import { merge } from 'lodash'
import xhr from 'xhr'
import waitUntil from 'async-wait-until'
import decode from 'jwt-decode'

import { ADMIN_API } from './consts'

export const getTokens = () => JSON.parse( localStorage.getItem( 'tokens' ) )

export const getToken = () => {
  const { access_token: token } = getTokens() || {}
  return token
}

export const getUserId = () => {
  const token = getToken()
  return token ? decode( token ).id : null
}

export const basicFetch = ( endpoint, options = {}, json = true ) => new Promise(
  ( resolve, reject ) => xhr(
    endpoint,
    merge( {}, { headers: {
      Authorization: `Bearer ${getToken()}`,
      ...( json && { 'Content-Type': 'application/json' } ),
    } }, options ),
    ( error, { statusCode }, body ) => {
    // Reject on browser/network error
      if ( error ) return reject( error )

      const { err, message, ...rest } = JSON.parse( body )

      // Reject on server error response
      // eslint-disable-next-line prefer-promise-reject-errors
      if ( err ) return reject( { err, message, statusCode } )
      return resolve( rest )
    },
  ),
)

let refreshing = false
export const refreshToken = async force => {
  // Don't allow others to refresh if a token is already refreshing
  if ( refreshing ) await waitUntil( () => !refreshing )

  // Load tokens
  const tokens = getTokens() || {}
  const { refresh_token: token, expires_at: expires } = tokens

  // No refresh token => they've got to login
  if ( !token ) return null

  // If the refresh token doesn't expire in about 7 seconds, don't bother refreshing
  const expired = Date.now() >= ( expires - 7 ) * 1000
  if ( !force && !expired ) return tokens

  // Prevent other calls from refreshing
  refreshing = true

  // Actually refresh the token
  const { success, ...tokenSet } = await basicFetch( `${ADMIN_API}/refresh`, {
    method: 'POST',
    body: JSON.stringify( { token } ),
  } ).finally( () => { refreshing = false } )

  // Serialise and save the tokens
  localStorage.setItem( 'tokens', JSON.stringify( tokenSet ) )

  return tokens
}

const refreshFetch = ( ...params ) => refreshToken()
  .then( () => basicFetch( ...params ) )
  .catch( async err => {
    // Force a token refresh if unauthorised and try again
    if ( err.statusCode === 401 ) {
      await refreshToken( true )
      return basicFetch( ...params )
    }
    // Otherwise just rethrow
    throw err
  } )

export default refreshFetch
