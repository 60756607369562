import React from 'react'
import { string, number } from 'prop-types'
import Countup from 'react-countup'

import { Grid, Statistic } from 'semantic-ui-react'

const GroupStatistic = ( { label, value, players, scouts } ) => (
  <Grid columns={players || scouts ? 2 : 1} textAlign="center" padded verticalAlign="middle">
    <Grid.Row>
      <Statistic>
        <Statistic.Value><Countup end={value || players + scouts || 0} /></Statistic.Value>
        <Statistic.Label>{label}</Statistic.Label>
      </Statistic>
    </Grid.Row>
    { ( players || scouts || null ) && (
    <Grid.Row>
      <Grid.Column>
        <Statistic size="mini">
          <Statistic.Value><Countup end={players || 0} /></Statistic.Value>
          <Statistic.Label>Players</Statistic.Label>
        </Statistic>
      </Grid.Column>
      <Grid.Column>
        <Statistic size="mini">
          <Statistic.Value><Countup end={scouts || 0} /></Statistic.Value>
          <Statistic.Label>Scouts</Statistic.Label>
        </Statistic>
      </Grid.Column>
    </Grid.Row>
    ) }
  </Grid>
)

GroupStatistic.propTypes = {
  label: string,
  value: number,
  players: number,
  scouts: number,
}

GroupStatistic.defaultProps = {
  label: '',
  value: 0,
  players: 0,
  scouts: 0,
}

export default GroupStatistic
