import React, { useEffect, useState } from 'react'
import { string, arrayOf } from 'prop-types'
import { toast } from 'react-toastify'

import { Table, Container, Header, Visibility } from 'semantic-ui-react'

import { SCOUT_TYPE } from '@skouted/common/lib/consts'

import { getUnverifiedScouts, verifyScout, banUser } from '../lib/controller'
import { getClub } from '../lib/utils'

import ConfirmButton from '../components/ConfirmButton'

import './Verify.css'

const Row = ( { id, verifyRequestedAt, name, phone, club, email, documents } ) => {
  const [ disabled, setDisabled ] = useState( false )
  const onActionClick = fn => () => {
    setDisabled( true )
    fn()
  }

  return (
    <Table.Row key={id} disabled={disabled}>
      <Table.Cell>{new Date( verifyRequestedAt ).toUTCString()}</Table.Cell>
      <Table.Cell>{name}</Table.Cell>
      <Table.Cell>{getClub( { type: SCOUT_TYPE, club } )}</Table.Cell>
      <Table.Cell>{phone}</Table.Cell>
      <Table.Cell>{email}</Table.Cell>
      <Table.Cell>
        {documents.map( ( link, index ) => (
          <a key={link} className="document link" href={link} target="_blank" rel="noopener noreferrer">
            {`File ${index + 1}`}
          </a>
        ) )}
      </Table.Cell>
      <Table.Cell>
        <ConfirmButton
          disabled={disabled}
          color="green"
          content="Verify"
          onClick={onActionClick( () => verifyScout( id ) )}
        />
        <ConfirmButton
          disabled={disabled}
          color="red"
          content="Ban"
          onClick={onActionClick( () => banUser( id ) )}
        />
      </Table.Cell>
    </Table.Row>
  )
}

Row.propTypes = {
  id: string.isRequired,
  verifyRequestedAt: string.isRequired,
  name: string.isRequired,
  phone: string.isRequired,
  club: string,
  email: string.isRequired,
  documents: arrayOf( string ),
}

Row.defaultProps = {
  club: null,
  documents: [],
}

const Verify = () => {
  const [ unverifiedScouts, setUnverifiedScouts ] = useState( [] )
  const [ timestamp, setTimestamp ] = useState( 0 )

  const loadUnverifiedScouts = () => getUnverifiedScouts( timestamp )
    .then( ( { unverified } ) => setUnverifiedScouts( [ ...unverifiedScouts, ...unverified ] ) )
    .catch( ( { err } ) => toast.error( err ) )

  useEffect( () => { loadUnverifiedScouts() }, [ timestamp ] )

  const loadMore = () => {
    const [ { verifyRequestedAt } ] = unverifiedScouts.slice( -1 )
    setTimestamp( new Date( verifyRequestedAt ).getTime() )
  }

  return (
    <Container className="verify">
      <Header textAlign="center">Verify Scouts</Header>

      <Visibility continuous onBottomVisible={loadMore}>
        <Table celled striped selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Timestamp</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Club</Table.HeaderCell>
              <Table.HeaderCell>Phone Number</Table.HeaderCell>
              <Table.HeaderCell>Email Address</Table.HeaderCell>
              <Table.HeaderCell>Documents</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {unverifiedScouts.map( props => <Row {...props} /> )}
          </Table.Body>
        </Table>
        {!unverifiedScouts.length && <Header size="huge" textAlign="center">All clear :)</Header>}
      </Visibility>

    </Container>
  )
}
export default Verify
