import querystring from 'qs'

import fetch, { basicFetch } from './fetch'
import { ADMIN_API } from './consts'

export const login = ( email, password ) => basicFetch( `${ADMIN_API}/login`, {
  method: 'POST',
  body: JSON.stringify( { email, password } ),
} ).then( ( { success, ...tokens } ) => localStorage.setItem( 'tokens', JSON.stringify( tokens ) ) )

export const logout = () => localStorage.clear()

export const getUser = uid => fetch( `${ADMIN_API}/user/${uid}` ).then( ( { user } ) => user )

export const search = filters => fetch( `${ADMIN_API}/search/?${querystring.stringify( filters )}` )

export const getStats = () => fetch( `${ADMIN_API}/stats` )

export const banUser = id => fetch( `${ADMIN_API}/user/${id}/ban`, { method: 'PUT' } )

export const unbanUser = id => fetch( `${ADMIN_API}/user/${id}/ban`, { method: 'DELETE' } )

export const makeModerator = id => fetch( `${ADMIN_API}/user/${id}/moderator`, { method: 'PUT' } )

export const removeModerator = id => fetch( `${ADMIN_API}/user/${id}/moderator`, { method: 'DELETE' } )

export const getFlagged = timestamp => fetch( `${ADMIN_API}/reported?timestamp=${timestamp}` )

export const dismissItem = id => fetch( `${ADMIN_API}/reported/${id}/dismiss`, { method: 'PUT' } )

export const removeItem = id => fetch( `${ADMIN_API}/reported/${id}/remove`, { method: 'PUT' } )

export const getOffences = id => fetch( `${ADMIN_API}/user/${id}/offences` )

export const getUnverifiedScouts = timestamp => fetch( `${ADMIN_API}/unverified?timestamp=${timestamp}` )

export const verifyScout = id => fetch( `${ADMIN_API}/user/${id}/verify`, { method: 'PUT' } )

export const getEvents = timestamp => fetch( `${ADMIN_API}/audit?timestamp=${timestamp}` )
