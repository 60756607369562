import { SCOUT_TYPE, PLAYER_TYPE, SPECTATOR_TYPE } from '@skouted/common/lib/consts'

/**
 * Calculates the number of years since `startDate` to now
 * @param {Date} startDate The date to calculate age from
 * @returns {Number} The age since the `startDate`
 */
export const getAge = startDate => {
  const today = new Date()
  const birthDate = new Date( startDate )

  let age = today.getFullYear() - birthDate.getFullYear()
  const month = today.getMonth() - birthDate.getMonth()

  if ( month < 0 || ( month === 0 && today.getDate() < birthDate.getDate() ) ) {
    age -= 1
  }

  return Number.isNaN( age ) ? 0 : age
}

export const getClub = ( { club, type } ) => ( {
  [ SPECTATOR_TYPE ]: 'Football Fan',
  [ SCOUT_TYPE ]: club || 'Talent Scout',
  [ PLAYER_TYPE ]: club || 'Free Agent',
} )[ type ]
